<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!--          <vuexy-logo />-->
          <h2 class="brand-t1ext text-primary ml-1">
            TradeBlade
          </h2>
        </b-link>

        <!--        <b-card-title class="mb-1">-->
        <!--          Adventure starts here 🚀-->
        <!--        </b-card-title>-->
        <!--        <b-card-text class="mb-2">-->
        <!--          Make your app management easy and fun!-->
        <!--        </b-card-text>-->

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- username -->
            <b-form-group
              label="Имя"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                  name="register-name"
                  placeholder="Имя"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Пароль"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password confirm-->
            <b-form-group
              label="Повторите пароль"
              label-for="password-confirm"
            >
              <validation-provider
                #default="{ errors }"
                name="password-confirm"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-confirm"
                    v-model="passwordConfirm"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- telegram -->
            <b-form-group
              label="Telegram"
              label-for="telegram"
            >
              <validation-provider
                #default="{ errors }"
                name="telegram"
              >
                <b-form-input
                  id="telegram"
                  v-model="telegram"
                  :state="errors.length > 0 ? false:null"
                  name="register-telegram"
                  placeholder="Telegram"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!--            <b-form-group>-->
            <!--              <b-form-checkbox-->
            <!--                id="register-privacy-policy"-->
            <!--                v-model="status"-->
            <!--                name="checkbox-1"-->
            <!--              >-->
            <!--                I agree to-->
            <!--                <b-link>privacy policy & terms</b-link>-->
            <!--              </b-form-checkbox>-->
            <!--            </b-form-group>-->

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Регистрация
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>У вас уже есть аккаунт? </span>
          <b-link :to="{name:'auth-login-v1'}">
            <span>Войти</span>
          </b-link>
        </b-card-text>

        <!--        <div class="divider my-2">-->
        <!--          <div class="divider-text">-->
        <!--            or-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        &lt;!&ndash; social buttons &ndash;&gt;-->
        <!--        <div class="auth-footer-btn d-flex justify-content-center">-->
        <!--          <b-button-->
        <!--            variant="facebook"-->
        <!--            href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="FacebookIcon" />-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--            variant="twitter"-->
        <!--            href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="TwitterIcon" />-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--            variant="google"-->
        <!--            href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="MailIcon" />-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--            variant="github"-->
        <!--            href="javascript:void(0)"-->
        <!--          >-->
        <!--            <feather-icon icon="GithubIcon" />-->
        <!--          </b-button>-->
        <!--        </div>-->
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      name: '',
      password: '',
      passwordConfirm: '',
      telegram: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      registration: 'auth/registration',
    }),
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          const uri = window.location.search.substring(1)

          this.registration({
            name: this.name,
            email: this.regEmail,
            telegram: this.telegram,
            password: this.password,
            password_confirmation: this.passwordConfirm,
            query_string: uri,
          })
            .then(() => {
              const { p } = this.$route.query

              if (p !== undefined) {
                const noScript = document.createElement('noscript')
                const imgEvent = document.createElement('img')
                imgEvent.setAttribute('height', '1')
                imgEvent.setAttribute('width', '1')
                imgEvent.setAttribute('src', `https://www.facebook.com/tr?id=${p}&ev=Lead&noscript=1`)

                noScript.appendChild(imgEvent)
                document.head.appendChild(noScript)
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Вы успешно зарегистрировались',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'auth-login' })
            })
            .catch(errors => {
              let textError = ''

              if (errors.response.status === 422) {
                textError = 'Проверьте введенные данные'

                this.$refs.registerForm.setErrors(errors.response.data.errors)
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка',
                  icon: 'BellIcon',
                  text: textError,
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
